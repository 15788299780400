import './../template/nav.min.css'
import './style.min.css'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'

const isFR = document.documentElement.lang == 'fr'

// DOM elements
const scrollHeight = document.querySelector('.scroll-height')
const canvas = document.querySelector('canvas.background')

const navbar = document.querySelector('.navbar')
const navOptions = document.querySelectorAll('.navbar li')
const navLinks = document.querySelectorAll('.navbar li a')
const navLinkDashes = document.querySelectorAll('.navbar li .dash')
const navButton = document.querySelector('.nav-button')
const navBurger = document.querySelectorAll('.nav-button .line')
const navRibbons = [
    document.querySelector('.nav-bg .ribbon-1'),
    document.querySelector('.nav-bg .ribbon-2'),
    document.querySelector('.nav-bg .ribbon-3'),
]

const donateButton = document.querySelector('.donate-button')
const donateCircle = document.querySelector('.donate-circle')
const coinContainer = document.querySelector('.donate-button .coin-container')
const coinRotate = document.querySelector('.donate-button .coin-rotate')
const boxContainer = document.querySelector('.donate-button .box-container')
const donateBox = document.querySelector('.donate-button .box')

const welcomeText = document.querySelector('.welcome')
const nonProfitText = document.querySelector('.non-profit')
const medText = document.querySelector('.med')
const ccsText = document.querySelector('.ccs')
const cancerText = document.querySelector('.cancer')
const ctaText = document.querySelector('.cta')

const ctaCircle = document.querySelector('.cta-circle')
const ctaArrow = document.querySelector('.cta .arrow')
const top = document.querySelector('.arrow .top')
const middle = document.querySelector('.arrow .middle')
const bottom = document.querySelector('.arrow .bottom')

// Create scroll indicator by adding a p and a div containing the scroll icon
const scrollIndicator = document.querySelector('.scroll-indicator')
const reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches

const NUM_PHOTOS = 65
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
    shadowMap: window.innerWidth > 800 ? 2048 : 1024,
}

// Variables
let isMobile = false
let loading = null
let rendering = true
let previousTime = null
let managerLoaded = false

let prevTime = 0
let prevMouseX = 0
let prevMouseY = 0
let cursorAnimationPaused = false
let images = []
let photoIndex = null
let prevPhotoIndex = null
let photoSpacing = calculatePhotoSpacing()
function calculatePhotoSpacing() {
    // Each image size is Min(27vw, 24vh)
    return Math.pow(Math.min(0.225 * window.innerWidth, 0.2 * window.innerHeight), 2)
}

let navOpen = false
let navBarEnabled = false
let navLinksEnabled = false
let mouseMoveEnabled = false
let daffodilAnimationEnabled = false

let codaContainer = null
let cMesh = null
let oMesh = null
let aMesh = null
let cInitialX = null
let oInitialX = null
let aInitialX = null
let codaInViewport = true
let charityInViewport = false
let ctaInViewport = false

let heartMixer = null
let heartClip = null
let handsMixer = null
let handsClip = null

let daffodilMixer = null
let daffodilClip = null
let daffodilAction = null
let daffodilBloomed = false

// Prevent saving the scroll position
if (history.scrollRestoration) {
    history.scrollRestoration = 'manual'
}
// Scroll height depends on initial screen height and stays the same unless reloaded
if (window.screen.height <= 600) {
    scrollHeight.style.height = '12000px'
} else if (window.screen.height <= 800) {
    scrollHeight.style.height = '14000px'
} else if (window.screen.height <= 1000) {
    scrollHeight.style.height = '16000px'
} else {
    scrollHeight.style.height = '18000px'
}

// Initialize ScrollTrigger
gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({
    trigger: scrollHeight,
})
// Text floats in from above
gsap.set([nonProfitText, medText, ccsText, cancerText, ctaText], {
    y: '-10vh',
})
// Sets transform origin for donate icon so it scales up nicely
// and for the arrow components so they fold down neatly into a single line
gsap.set([navButton, donateCircle, coinContainer, coinRotate, boxContainer, top, bottom], {
    transformOrigin: (index) => {
        if (index === 0) return '15% 15%'
        if (index === 1) return '85% 15%'
        if (index === 2) return '50% 25%'
        if (index === 3) return '50% 50%'
        if (index === 4) return '50% -50%'
        if (index === 5) return '94% 140%'
        if (index === 6) return '95% -40%'
    },
})
// Center everything
gsap.set([coinRotate, donateBox, ctaText], {
    xPercent: -50,
    x: 0,
})
// Center the burger icon and save initial values with gsap
gsap.set(navBurger, {
    xPercent: -50,
    yPercent: -50,
    x: 0,
    y: 0,
    scale: 0.99,
})
// Translate ribbons off-screen
gsap.set(navRibbons, {
    xPercent: -40,
    yPercent: (index) => {
        return index === 1 ? -200 : 200
    },
    x: 0,
    y: 0,
})
// Translate nav options right for style
gsap.set(navOptions, {
    xPercent: (index) => {
        if (index === 5) {
            return 7 * index
        }
        return 6 * index
    },
    transformOrigin: '0 100%',
})
// Scale the dashes to 0 for nav link hover animation
gsap.set(navLinkDashes, {
    transformOrigin: '100% 0',
    scaleX: 0,
})

// Check if browser supports WebGL
function isWebGLAvailable() {
    try {
        const canvas = document.createElement('canvas')
        return !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')))
    } catch (e) {
        return false
    }
}
if (isWebGLAvailable()) {
    init()
} else {
    document.querySelector('.error').style.display = 'flex'
    // Show error message elements on screen
    console.warn(
        isFR
            ? 'Votre navigateur ne supporte pas WebGL. \n\nVeuillez rouvrir cette page dans un navigateur plus récent, tel que Chrome, Edge, ou Firefox. '
            : 'Your browser does not support WebGL. \n\nPlease reopen this page on a modern browser, such as Chrome, Edge, or Firefox. '
    )
}

// Does not execute if WebGL not available
function init() {
    // Create timeline for animation after loading
    const loadedTransition = gsap.timeline({
        defaults: { ease: 'power1.inOut' },
        paused: true,
        delay: 1.2,
        onComplete: () => {
            // Show scrollbar
            if (!navOpen) {
                document.body.style.overflowY = 'auto'
            }
            // Reset scroll position
            window.scrollTo(0, 0)
            // Main three.js animations
            createScrollAnimation()
            // Text fades in and floats down
            createTextAnimation()
            // Infinite arrow animation at the cta
            createArrowAnimation()
            // Preload all images that appear on mousemove
            preloadImages()
            // Mousemove animation
            mouseMoveEnabled = true

            // Show the scroll indicator
            scrollIndicator.style.opacity = 1
            document.addEventListener(
                'scroll',
                () => {
                    // Fade out indicator
                    setTimeout(() => {
                        scrollIndicator.style.opacity = 0
                    }, 600)
                },
                { once: true }
            )
        },
    })

    // Create timeline for main three.js animations
    const scroll = gsap.timeline({
        defaults: { ease: 'power2.inOut' },
        scrollTrigger: {
            start: 'top top',
            end: 'bottom bottom',
            scrub: 1,
            // Update function-based values on resize
            invalidateOnRefresh: true,
        },
    })

    // Create infinite timeline for arrow animation
    const arrow = new gsap.timeline({
        repeatDelay: 3,
        repeat: -1,
        paused: true,
    })

    // Transition between loading and Coda
    function createLoadedAnimation() {
        // Move camera up and back
        loadedTransition.to(camera.position, {
            x: 0,
            y: 0.8,
            z: 4,
            duration: 2,
        })
        // Make camera look up
        loadedTransition.to(
            camera.rotation,
            {
                x: 0.035 * Math.PI,
                duration: 2,
            },
            '<'
        )
        // Turn on lamp
        loadedTransition.to(
            lamp,
            {
                intensity: 0.8,
                duration: 3,
            },
            '<'
        )
        // Turn off loading light
        loadedTransition.to(
            loadingLight,
            {
                intensity: 0,
                duration: 1,
            },
            '<0.5'
        )
        // Turn on ambient light
        loadedTransition.to(
            ambientLight,
            {
                intensity: 0.8,
                duration: 2,
            },
            '<0.2'
        )
        // Show welcome text
        loadedTransition.to(
            welcomeText,
            {
                autoAlpha: 1,
                duration: 1,
                stagger: 0.3,
                ease: 'power2.in',
            },
            '>-1.2'
        )

        // Precalculate values
        loadedTransition.progress(1, true).progress(0, true)
    }

    function preloadImages() {
        for (let i = 1; i <= NUM_PHOTOS; i++) {
            let loadedImg = new Image()
            loadedImg.src = `/images/photos/360/${i}.jpg`
            images.push(loadedImg)
        }
    }

    // Mousemove animation
    document.addEventListener('mousemove', onClientMove)
    document.addEventListener('touchmove', onClientMove)
    function onClientMove(e) {
        // Don't run function if nav is open or user prefers no animations
        if (!mouseMoveEnabled || reduceMotion) return

        let x = e.clientX || e.touches[0].clientX
        let y = e.clientY || e.touches[0].clientY

        // Show an image every 300ms on mousemove or if previous image is far enough
        if (!cursorAnimationPaused) {
            let currTime = Date.now()
            if (currTime - prevTime > 300 || Math.pow(x - prevMouseX, 2) + Math.pow(y - prevMouseY, 2) > photoSpacing) {
                // Prevent same image from appearing twice in a row
                do {
                    photoIndex = Math.ceil(Math.random() * NUM_PHOTOS)
                } while (photoIndex === prevPhotoIndex)
                addImage(x, y, photoIndex)
                prevMouseX = x
                prevMouseY = y
                prevPhotoIndex = photoIndex
                prevTime = currTime
            }
        }

        // Move camera down with mouse
        gsap.to(cameraContainer.rotation, {
            x: ((y / window.innerHeight - 0.3) / 48) * Math.PI,
            overwrite: true,
            duration: 2,
        })

        if (codaInViewport) {
            // Rotate Coda with mouse movement
            gsap.to(codaContainer.rotation, {
                x: -(y / window.innerHeight) / 3,
                y: -((x / window.innerWidth) * 2 - 1) / 3,
                overwrite: true,
                duration: 3,
            })
        }

        if (ctaInViewport) {
            // Calculate distance between mouse and center of the circle
            const boundingRect = ctaCircle.getBoundingClientRect()
            const deltaX = x - (boundingRect.x + boundingRect.width / 2)
            const deltaY = y - (boundingRect.y + boundingRect.height / 2)
            const normalizedDeltaX = deltaX / Math.max(window.innerWidth, window.innerHeight)
            const normalizedDeltaY = deltaY / Math.max(window.innerWidth, window.innerHeight)
            const distance = normalizedDeltaX * normalizedDeltaX + normalizedDeltaY * normalizedDeltaY

            if (distance < 0.08) {
                // Move circle closer to mouse and increase size the closer the mouse gets to the center of the circle
                gsap.to([ctaCircle, ctaArrow], {
                    x: (index) => {
                        return index === 0 ? deltaX / 2 : deltaX / 16
                    },
                    y: (index) => {
                        return index === 0 ? deltaY / 2 : deltaY / 16
                    },
                    scale: (index) => {
                        return index === 0 ? Math.max(3 - distance * 50, 1) : 1
                    },
                    overwrite: true,
                    duration: 1.5,
                    ease: 'power2.out',
                })
            } else {
                // Move circle back to center and reduce size to 1
                gsap.to([ctaCircle, ctaArrow], {
                    x: 0,
                    y: 0,
                    scale: 0.99,
                    overwrite: true,
                    duration: 1.5,
                    ease: 'power2.out',
                })
            }
        }
    }

    function addImage(x, y, index) {
        // Create element, add class, src and position
        let img = document.createElement('img')
        img.classList.add('cursor-image')
        img.style.top = `calc(${y}px - 12vmin)`
        img.style.left = `calc(${x}px - 12vmin)`
        // Animate the image in and then out
        let tl = gsap.timeline({ paused: true })
        tl.fromTo(
            img,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                duration: 0.3,
                ease: 'linear',
            }
        )
        tl.to(img, {
            opacity: 0,
            yPercent: 70,
            duration: 0.7,
            ease: 'expo.in',
            onComplete: () => {
                img.remove()
            },
        })
        img.onload = () => {
            tl.play()
        }
        img.src = `/images/photos/360/${index}.jpg`
        document.body.appendChild(img)
    }

    // Main three.js animations
    function createScrollAnimation() {
        // Non-profit transition
        scroll.to(camera.position, {
            x: () => {
                return isMobile ? -0.9 : -0.6
            },
            y: () => {
                return isMobile ? 2.5 : 2.8
            },
            z: () => {
                return isMobile ? 7.2 : 7.4
            },
            duration: 2,
            ease: 'power1.out',
        }) // 0
        scroll.to(
            camera.rotation,
            {
                x: -0.5 * Math.PI,
                ease: 'power1.out',
                duration: 2.5,
            },
            '<'
        ) // 0
        scroll.to(
            lamp,
            {
                intensity: 0.6,
                angle: Math.PI / 5,
                duration: 2,
            },
            '<'
        ) // 0
        scroll.to(
            lamp.position,
            {
                x: -0.4,
                y: 3,
                z: 7.1,
                duration: 2,
            },
            '<'
        ) // 0
        scroll.to(
            lampTarget.position,
            {
                x: -0.7,
                y: 0,
                z: 7.4,
                ease: 'power3.inOut',
                duration: 2,
            },
            '<'
        ) // 0
        scroll.to(
            ambientLight.color,
            {
                r: 0.3,
                g: 0.5,
                b: 1,
                duration: 1.5,
            },
            '<1'
        ) // 1.0

        // Stethoscope transition
        scroll.to(
            lamp.position,
            {
                x: 0.2,
                y: 3,
                z: 11,
                ease: 'power2.in',
                duration: 2,
            },
            '>'
        ) // 0
        scroll.to(
            lampTarget.position,
            {
                x: 0.7,
                y: 5,
                z: 13,
                ease: 'power2.in',
                duration: 1,
            },
            '<'
        ) // 0
        scroll.to(
            camera.rotation,
            {
                x: () => {
                    return (isMobile ? -0.6 : -0.7) * Math.PI
                },
                duration: 2,
            },
            '<'
        ) // 0
        scroll.to(
            camera.position,
            {
                x: 0.6,
                z: 12.7,
                duration: 1.5,
            },
            '<'
        ) // 0
        scroll.to(
            camera.position,
            {
                y: 8.2,
                duration: 1.5,
            },
            '<1'
        ) // 1.0
        scroll.to(
            ambientLight.color,
            {
                r: 0.4,
                g: 0.75,
                duration: 1.5,
            },
            '<'
        ) // 1.0

        // Daffodil transition
        scroll.to(
            camera.position,
            {
                x: () => {
                    return isMobile ? -0.8 : -0.2
                },
                y: () => {
                    return isMobile ? 7.8 : 7.1
                },
                z: () => {
                    return isMobile ? 1.8 : 1.6
                },
                duration: 2,
            },
            '>'
        ) // 0
        scroll.to(
            camera.rotation,
            {
                x: -1.5 * Math.PI,
                duration: 2.5,
            },
            '<'
        ) // 0
        scroll.to(
            lamp.position,
            {
                x: 0,
                y: 7,
                z: 2.2,
                duration: 1,
            },
            '<0.5'
        ) // 0.5
        scroll.to(
            lampTarget.position,
            {
                x: -0.5,
                y: 10,
                z: 1.4,
                duration: 1,
            },
            '<0.5'
        ) // 1.0
        scroll.to(
            ambientLight.color,
            {
                r: 0.9,
                g: 0.9,
                b: 0.7,
                duration: 1.5,
            },
            '<'
        ) // 1.0

        // Ribbon transition
        scroll.to(
            lamp,
            {
                intensity: 0,
                duration: 2,
            },
            '>'
        ) // 0
        scroll.to(
            lamp.position,
            {
                x: 0.2,
                y: 4,
                z: 1,
                ease: 'power2.in',
                duration: 2,
            },
            '<'
        ) // 0
        scroll.to(
            lampTarget.position,
            {
                x: 0,
                y: 0,
                z: 0,
                ease: 'power2.in',
                duration: 2,
            },
            '<'
        ) // 0
        scroll.to(
            cMesh.position,
            {
                x: cInitialX - 32,
                ease: 'none',
                duration: 0.1,
            },
            '<'
        ) // 0
        scroll.to(
            oMesh.position,
            {
                x: oInitialX - 16,
                ease: 'none',
                duration: 0.1,
            },
            '<'
        ) // 0
        scroll.to(
            aMesh.position,
            {
                x: aInitialX + 16,
                ease: 'none',
                duration: 0.1,
            },
            '<'
        ) // 0
        scroll.to(
            ambientLight.color,
            {
                r: 0,
                g: 0,
                b: 0,
                duration: 1,
            },
            '<0.5'
        ) // 0.5
        scroll.to(
            ribbonLight,
            {
                intensity: 1,
                ease: 'none',
                duration: 1.5,
            },
            '<'
        ) // 0.5
        scroll.to(
            camera.position,
            {
                x: () => {
                    return isMobile ? -0.2 : 0.1
                },
                y: () => {
                    return isMobile ? 0.9 : 1.2
                },
                z: () => {
                    return isMobile ? 1.4 : 1.8
                },
                duration: 2.5,
            },
            '<-0.5'
        ) // 0
        scroll.to(
            camera.rotation,
            {
                x: -2.2 * Math.PI,
                duration: 2,
            },
            '<'
        ) // 0
        scroll.to(
            camera.rotation,
            {
                z: (3 / 4) * Math.PI,
                duration: 1.5,
            },
            '<1'
        ) // 1

        // Ribbon light passes around ribbon
        scroll.to(
            ribbonLight.position,
            {
                x: 0.5,
                y: 0.1,
                ease: 'none',
                duration: 2,
            },
            '>-1.2'
        ) // -1.2
        scroll.to(
            ribbonLight,
            {
                intensity: 0,
                duration: 1.5,
            },
            '<1.2'
        ) // 0

        // Coda transition
        scroll.to(
            camera.rotation,
            {
                x: -1.99 * Math.PI,
                z: 0,
                duration: 2.5,
            },
            '<'
        ) // 0
        scroll.to(
            camera.position,
            {
                x: 0,
                y: 0.8,
                z: 4,
                duration: 2,
            },
            '<'
        ) // 0
        scroll.to(
            cMesh.position,
            {
                x: cInitialX,
                ease: 'power3.inOut',
                duration: 2.5,
            },
            '<'
        ) // 0
        scroll.to(
            oMesh.position,
            {
                x: oInitialX,
                ease: 'power3.inOut',
                duration: 2.5,
            },
            '<'
        ) // 0
        scroll.to(
            aMesh.position,
            {
                x: aInitialX,
                ease: 'power3.inOut',
                duration: 2.5,
            },
            '<'
        ) // 0
        scroll.to(
            lamp,
            {
                intensity: 1,
                angle: Math.PI / 3,
                duration: 2,
            },
            '<0.5'
        ) // 0.5
        scroll.to(
            ambientLight.color,
            {
                r: 0.533,
                g: 0.467,
                b: 1,
                duration: 1.5,
            },
            '<0.5'
        ) // 1

        // Precalculate values
        scroll.progress(1, true).progress(0, true)
    }

    // Text fades in and floats down
    function createTextAnimation() {
        ScrollTrigger.create({
            start: '4% top',
            end: '13.6% top',
            onEnter: () => {
                hideText(welcomeText)
                charityInViewport = true
                cursorAnimationPaused = true
            },
            onLeaveBack: () => {
                showText(welcomeText)
                charityInViewport = false
                cursorAnimationPaused = false
            },
            onLeave: () => {
                showText(nonProfitText)
                codaInViewport = false
            },
            onEnterBack: () => {
                hideText(nonProfitText)
                codaInViewport = true
            },
        })
        ScrollTrigger.create({
            start: '22% top',
            end: '33.6% top',
            onEnter: () => {
                hideText(nonProfitText)
            },
            onLeaveBack: () => {
                showText(nonProfitText)
            },
            onLeave: () => {
                showText(medText)
                charityInViewport = false
            },
            onEnterBack: () => {
                hideText(medText)
                charityInViewport = true
            },
        })
        ScrollTrigger.create({
            start: '44% top',
            end: '52% top',
            onEnter: () => {
                hideText(medText)
            },
            onLeaveBack: () => {
                showText(medText)
            },
            onLeave: () => {
                showText(ccsText)
                bloomDaffodil()
                daffodilAnimationEnabled = true
            },
            onEnterBack: () => {
                hideText(ccsText)
                reverseDaffodil()
                daffodilAnimationEnabled = false
            },
        })
        ScrollTrigger.create({
            start: '62% top',
            end: '68% top',
            onEnter: () => {
                hideText(ccsText)
                daffodilAnimationEnabled = false
            },
            onLeaveBack: () => {
                showText(ccsText)
                daffodilAnimationEnabled = true
            },
            onLeave: () => {
                showText(cancerText)
                codaInViewport = true
            },
            onEnterBack: () => {
                hideText(cancerText)
                codaInViewport = false
            },
        })
        ScrollTrigger.create({
            start: '84% top',
            end: '99.5% bottom',
            onEnter: () => {
                hideText(cancerText)
            },
            onLeaveBack: () => {
                showText(cancerText)
            },
            onLeave: () => {
                showText(ctaText)
                arrow.restart()
                ctaInViewport = true
            },
            onEnterBack: () => {
                hideText(ctaText)
                arrow.pause()
                ctaInViewport = false
            },
        })
    }

    // Infinite arrow animation at the cta
    function createArrowAnimation() {
        arrow.to(
            [top, bottom],
            {
                rotation: (index) => {
                    return index === 0 ? -35 : 35
                },
                duration: 0.5,
                ease: 'power2.out',
            },
            '<2'
        )
        arrow.fromTo(
            [top, middle, bottom],
            {
                xPercent: -10,
            },
            {
                xPercent: 200,
                duration: 0.4,
                ease: 'back.in(2)',
            },
            '>-0.1'
        )
        arrow.fromTo(
            [top, middle, bottom],
            {
                xPercent: -200,
            },
            {
                xPercent: -10,
                duration: 0.4,
                immediateRender: false,
                ease: 'back.out(2)',
            }
        )
        arrow.to(
            [top, bottom],
            {
                rotation: 0,
                duration: 0.3,
                ease: 'power2.in',
            },
            '>-0.1'
        )

        // Precalculate values
        arrow.progress(1, true).progress(0, true)
    }

    // Animate text in
    function showText(textElement) {
        // Make text fade in and float down
        gsap.to(textElement, {
            y: 0,
            autoAlpha: 1,
            duration: 1,
            stagger: 0.3,
            // Kills any previous animations on the same element
            overwrite: 'auto',
            ease: 'circ.out',
        })
    }

    // Animate text out
    function hideText(textElement) {
        // Float up and fade out
        gsap.to(textElement, {
            y: '-10vh',
            autoAlpha: 0,
            duration: 0.5,
            stagger: 0.2,
            overwrite: 'auto',
            ease: 'circ.in',
        })
    }

    document.addEventListener('click', animateDaffodil)

    function animateDaffodil() {
        if (!daffodilAnimationEnabled) return

        // Reverse timescale if already bloomed
        if (!daffodilBloomed) {
            bloomDaffodil()
            return
        }
        reverseDaffodil()
    }
    function bloomDaffodil() {
        daffodilAction.setEffectiveTimeScale(2)
        daffodilBloomed = true
        daffodilAction.paused = false
        daffodilAction.play()
    }
    function reverseDaffodil() {
        daffodilAction.setEffectiveTimeScale(-6)
        daffodilBloomed = false
        daffodilAction.paused = false
        daffodilAction.play()
    }

    // Loaders
    const manager = new THREE.LoadingManager()
    const textureLoader = new THREE.TextureLoader(manager)
    const cubeTextureLoader = new THREE.CubeTextureLoader(manager)
    const gltfLoader = new GLTFLoader(manager)
    const dracoLoader = new DRACOLoader(manager)
    dracoLoader.setDecoderPath('/draco/')
    gltfLoader.setDRACOLoader(dracoLoader)

    // Called everytime one asset is loaded
    manager.onProgress = (url, itemsLoaded, itemsTotal) => {
        // If the loading text mesh has been loaded
        if (loading) {
            // Make loading object go down on y axis
            gsap.to(loading.position, {
                y: 0.05 - (0.082 * itemsLoaded) / Math.max(itemsTotal, 21),
                duration: 1.5,
                overwrite: true,
                ease: 'sine.inOut',
                onComplete: () => {
                    if (managerLoaded) {
                        // Play transition when everything is loaded
                        loadedTransition.play()
                    }
                },
            })
        }
    }

    manager.onLoad = () => {
        // Force three.js to render all meshes once
        scene.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.frustumCulled = false
            }
        })
        // Reset frustum culling after 1 second
        setTimeout(() => {
            scene.traverse((child) => {
                if (child instanceof THREE.Mesh) {
                    child.frustumCulled = true
                }
            })
        }, 1000)
        // Tell the onProgress animation that everything is ready
        managerLoaded = true
    }

    // Three.js scene
    const scene = new THREE.Scene()

    // Camera
    const camera = new THREE.PerspectiveCamera(calculateCameraFOV(), sizes.width / sizes.height, 0.01, 20)
    camera.rotation.x = -0.26 * Math.PI
    function calculateCameraFOV() {
        // Formula to ensure best fit of the camera's FOV to the screen size
        const fov = 60.3 * Math.pow(sizes.width / sizes.height, -0.66)
        if (fov > 70) {
            isMobile = true
        } else {
            isMobile = false
        }
        return Math.min(Math.max(fov, 45), 100)
    }
    const cameraContainer = new THREE.Object3D()
    cameraContainer.add(camera)
    scene.add(cameraContainer)

    // Ambient Light
    const ambientLight = new THREE.AmbientLight(0x8877ff, 0)
    scene.add(ambientLight)

    // Lamp looks at target
    const lampTarget = new THREE.Object3D()
    scene.add(lampTarget)

    // Lamp
    const lamp = new THREE.SpotLight(0xffffff, 0, 10, Math.PI / 3, 1, 0.2)
    lamp.position.set(0.2, 4, 1)
    lamp.castShadow = true
    lamp.shadow.mapSize.set(sizes.shadowMap, sizes.shadowMap)
    lamp.shadow.camera.near = 1.4
    lamp.shadow.camera.far = 4.5
    lamp.target = lampTarget
    // Lamp moves with camera
    scene.add(lamp)

    // Loading light
    const loadingLight = new THREE.SpotLight(0xffffff, 0.2, 1, Math.PI / 3, 0.5, 1)
    loadingLight.castShadow = true
    loadingLight.shadow.mapSize.set(sizes.shadowMap, sizes.shadowMap)
    loadingLight.shadow.camera.near = 0.2
    loadingLight.shadow.camera.far = 0.4
    scene.add(loadingLight)

    // Ribbon light
    const ribbonLight = new THREE.SpotLight(0xffffff, 0, 2, Math.PI / 4, 1, 0.5)
    ribbonLight.position.set(-1.6, 1.6, 0.9)
    ribbonLight.castShadow = true
    ribbonLight.shadow.mapSize.set(512, 512)
    ribbonLight.shadow.camera.near = 0.1
    ribbonLight.shadow.camera.far = 1
    scene.add(ribbonLight)

    // Materials for loading.glb
    function updateLoadingMaterials() {
        const matteGrey = new THREE.MeshPhongMaterial({
            color: 0xe7e7e7,
            shininess: 20,
        })
        scene.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                if (child.name === 'Plane') {
                    child.receiveShadow = true
                    child.material = matteGrey
                } else if (child.name === 'Loading') {
                    loading = child
                    loading.castShadow = true
                    loading.receiveShadow = true
                    loading.material = matteGrey
                    loading.position.y = 0.05
                    // Camera starts a bit up and back to the loading text
                    camera.position.x = loading.position.x
                    camera.position.y = loading.position.y + 0.5
                    camera.position.z = loading.position.z + 0.5
                    // Set spotlight above loading text and make it point directly at it
                    loadingLight.position.x = loading.position.x
                    loadingLight.position.y = loading.position.y + 0.3
                    loadingLight.position.z = loading.position.z + 0.2
                    loadingLight.target = loading
                }
            }
        })
    }

    // Materials for coda.glb
    function updateCodaMaterials() {
        const silkNormalMap = textureLoader.load('/textures/silk-normal.jpg')
        silkNormalMap.repeat.set(12, 12)
        silkNormalMap.wrapS = THREE.RepeatWrapping
        silkNormalMap.wrapT = THREE.RepeatWrapping
        const matteBlack = new THREE.MeshPhongMaterial({
            color: 0x1a1b1c,
            shininess: 10,
        })
        const silk = new THREE.MeshPhongMaterial({
            color: 0x996eff,
            normalMap: silkNormalMap,
            normalScale: new THREE.Vector2(2, 2),
            shininess: 10,
        })
        scene.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                // Save position of each letter for later animations
                if (child.name === 'c') {
                    cMesh = child
                    cMesh.castShadow = true
                    cMesh.material = matteBlack
                    cInitialX = cMesh.position.x
                } else if (child.name === 'o') {
                    oMesh = child
                    oMesh.castShadow = true
                    oMesh.material = matteBlack
                    oInitialX = oMesh.position.x
                } else if (child.name === 'a') {
                    aMesh = child
                    aMesh.castShadow = true
                    aMesh.material = matteBlack
                    aInitialX = aMesh.position.x
                } else if (child.name === 'Ribbon') {
                    child.castShadow = true
                    child.receiveShadow = true
                    child.material = silk
                }
            }
            // Get the Object3D in order to change its position/rotation later on
            else if (child instanceof THREE.Object3D) {
                if (child.name === 'Coda') {
                    codaContainer = child
                } else if (child.name === 'RibbonPointAt') {
                    ribbonLight.target = child
                }
            }
        })
    }

    // Materials for charity.glb
    function updateCharityMaterials() {
        scene.traverse((child) => {
            const matteLavender = new THREE.MeshPhongMaterial({
                color: 0x996eff,
                shininess: 10,
            })
            const matteRed = new THREE.MeshPhongMaterial({
                color: 0xed1b2f,
                shininess: 10,
            })
            const glossyBlack = new THREE.MeshPhongMaterial({
                color: 0x1a1b1c,
                shininess: 90,
            })
            if (child instanceof THREE.Mesh) {
                if (child.name === 'Right' || child.name === 'Left') {
                    child.castShadow = true
                    child.receiveShadow = true
                    child.material = matteLavender
                } else if (child.name === 'HeartFill') {
                    child.castShadow = true
                    child.receiveShadow = true
                    child.material = matteRed
                } else if (child.name === 'HandsStroke' || child.name === 'HeartStroke') {
                    child.castShadow = true
                    child.receiveShadow = true
                    child.material = glossyBlack
                }
            }
            // Create animation mixer and animation action
            else if (child instanceof THREE.Object3D) {
                if (child.name === 'Heart') {
                    heartMixer = new THREE.AnimationMixer(child)
                    const heartAction = heartMixer.clipAction(heartClip)
                    heartAction.setLoop(THREE.LoopRepeat)
                    heartAction.play()
                } else if (child.name === 'Hands') {
                    handsMixer = new THREE.AnimationMixer(child)
                    const handsAction = handsMixer.clipAction(handsClip)
                    handsAction.setLoop(THREE.LoopRepeat)
                    handsAction.play()
                }
            }
        })
    }

    // Materials for stethoscope.glb
    function updateStethoscopeMaterials() {
        // Environment map texture
        const environmentMap = cubeTextureLoader.load([
            '/textures/metal/px.jpg',
            '/textures/metal/nx.jpg',
            '/textures/metal/py.jpg',
            '/textures/metal/ny.jpg',
            '/textures/metal/pz.jpg',
            '/textures/metal/nz.jpg',
        ])
        environmentMap.encoding = THREE.sRGBEncoding
        const rubber = new THREE.MeshPhongMaterial({
            color: 0x0a0b0c,
            shininess: 80,
        })
        const metal = new THREE.MeshStandardMaterial({
            roughness: 0.15,
            metalness: 1,
            envMap: environmentMap,
            envMapIntensity: 0.08,
        })
        const black = new THREE.MeshBasicMaterial({
            color: 0x000000,
        })
        scene.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                if (child.name === 'Body' || child.name === 'TubeMetal' || child.name === 'Stem') {
                    child.castShadow = true
                    child.material = metal
                } else if (
                    child.name === 'EarpieceLeft' ||
                    child.name === 'EarpieceRight' ||
                    child.name === 'TubeRubber' ||
                    child.name === 'GasketTop' ||
                    child.name === 'GasketBottom'
                ) {
                    child.castShadow = true
                    child.material = rubber
                } else if (child.name === 'BlackInside') {
                    child.material = black
                }
            }
        })
    }

    // Materials for daffodil.glb
    function updateDaffodilMaterials() {
        // Textures
        const petalColorMap = textureLoader.load('/textures/petal-color.jpg')
        const petalNormalMap = textureLoader.load('/textures/petal-normal.jpg')
        petalNormalMap.repeat.set(1, 2)
        petalNormalMap.wrapS = THREE.RepeatWrapping
        petalNormalMap.wrapT = THREE.RepeatWrapping
        // Materials
        const yellowPetal = new THREE.MeshPhongMaterial({
            map: petalColorMap,
            normalMap: petalNormalMap,
            normalScale: new THREE.Vector2(4, 4),
            shininess: 10,
            morphTargets: true,
        })
        scene.traverse((child) => {
            if (child instanceof THREE.Mesh && child.name === 'Daffodil') {
                child.castShadow = true
                child.receiveShadow = true
                child.material = yellowPetal

                daffodilMixer = new THREE.AnimationMixer(child)
                daffodilAction = daffodilMixer.clipAction(daffodilClip)
                daffodilAction.setLoop(THREE.LoopOnce)
                daffodilAction.clampWhenFinished = true
            }
        })
    }

    // Model
    gltfLoader.load('/models/loading.glb', (gltf) => {
        scene.add(gltf.scene)

        updateLoadingMaterials()

        // Create the gsap animation after scene, camera, lamps, etc. have been created
        createLoadedAnimation()

        // Start rendering once loading.glb is loaded
        window.requestAnimationFrame(tick)

        // Fade in canvas
        gsap.to(canvas, {
            opacity: 1,
            duration: 1,
            ease: 'power1.inOut',
        })

        loadCoda()
    })

    function loadCoda() {
        gltfLoader.load('/models/coda.glb', (gltf) => {
            scene.add(gltf.scene)

            updateCodaMaterials()

            loadCharity()
        })
    }
    function loadCharity() {
        gltfLoader.load('/models/charity.glb', (gltf) => {
            scene.add(gltf.scene)

            // Get the animation clips
            heartClip = gltf.animations[0]
            handsClip = gltf.animations[1]

            updateCharityMaterials()

            loadStethoscope()
        })
    }
    function loadStethoscope() {
        gltfLoader.load('/models/stethoscope.glb', (gltf) => {
            scene.add(gltf.scene)

            updateStethoscopeMaterials()

            loadDaffodil()
        })
    }
    function loadDaffodil() {
        gltfLoader.load('/models/daffodil.glb', (gltf) => {
            scene.add(gltf.scene)

            daffodilClip = gltf.animations[0]

            updateDaffodilMaterials()
        })
    }

    // Renderer
    const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true,
        powerPreference: 'high-performance',
        stencil: false,
    })
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1.5))
    renderer.physicallyCorrectLights = true
    renderer.outputEncoding = THREE.sRGBEncoding
    renderer.toneMapping = THREE.ACESFilmicToneMapping
    renderer.toneMappingExposure = 2
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap

    // Animation
    function tick(timestamp) {
        // Don't render if nav is open
        if (rendering) {
            // Update animation mixers
            if (previousTime) {
                let deltaTime = (timestamp - previousTime) * 0.001
                if (charityInViewport && heartMixer && handsMixer) {
                    heartMixer.update(deltaTime)
                    handsMixer.update(deltaTime)
                }
                if (daffodilMixer) {
                    daffodilMixer.update(deltaTime)
                }
            }
            previousTime = timestamp

            renderer.render(scene, camera)
        }

        window.requestAnimationFrame(tick)
    }

    // Resize
    window.addEventListener('resize', updateSizes)
    function updateSizes() {
        // Get window sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight

        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.fov = calculateCameraFOV()
        camera.updateProjectionMatrix()
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1.5))

        photoSpacing = calculatePhotoSpacing()
    }
    // Reset the sizes to prevent caching/loading bugs
    updateSizes()
} // End of init function

/* -------- START NAV -------- */

// Force a reload to prevent BFcache
window.addEventListener('pageshow', (e) => {
    if (e.persisted) {
        location.reload()
    }
})
// Enable menu button
navButton.style.pointerEvents = 'all'
// Enable donate button
donateButton.style.pointerEvents = 'all'

// Menu button hover effect and click listener
navButton.addEventListener('mouseenter', growNavButton)
navButton.addEventListener('focus', growNavButton)
navButton.addEventListener('mouseleave', shrinkNavButton)
navButton.addEventListener('focusout', shrinkNavButton)
navButton.addEventListener('click', toggleNav)
navButton.addEventListener('keyup', (e) => {
    if (e.keyCode === 13) {
        navButton.click()
    }
})

// Hover effect for menu button
function growNavButton() {
    gsap.to([navButton, navBurger], {
        scale: (index) => {
            return index === 0 ? 1.4 : 0.8
        },
        duration: 1,
        ease: 'power2.out',
    })

    // Don't animate xPercent on hover if navbar open (otherwise it interferes with navAnimation timeline)
    if (navOpen) return

    gsap.to(navBurger, {
        xPercent: (index) => {
            return 20 * index - 70
        },
        duration: 0.5,
        ease: 'circ.in',
    })
}
function shrinkNavButton() {
    gsap.to([navButton, navBurger], {
        scale: 0.99,
        duration: 1,
        ease: 'power2.out',
    })

    // Don't animate xPercent on hover if navbar open (otherwise it interferes with navAnimation timeline)
    if (navOpen) return

    gsap.to(navBurger, {
        xPercent: -50,
        duration: 0.5,
        ease: 'circ.in',
    })
}

// Timeline for navbar animation on click
const navAnimation = new gsap.timeline({
    paused: true,
})
// Burger to X animation on click
const burgerAnimation = navAnimation.to(navBurger, {
    xPercent: -50,
    yPercent: (index) => {
        return -185 * index + 185
    },
    rotation: (index) => {
        return index === 0 ? -400 : 400
    },
    opacity: (index) => {
        return index === 1 ? 0 : 1
    },
    duration: 1,
    ease: 'power4.inOut',
    onStart: () => {
        navOpen = true
        // Prevent clicking on the page
        navbar.style.pointerEvents = 'all'
        // Hide scrollbar
        document.body.style.overflowY = 'hidden'
    },
    onComplete: () => {
        shrinkNavButton()
    },
    onReverseComplete: () => {
        navOpen = false
        // Prevent navbar from blocking pointer events on the page
        navbar.style.pointerEvents = 'none'
        // Show scrollbar
        document.body.style.overflowY = 'auto'
        shrinkNavButton()
    },
})
// Ribbon background animation on click
navAnimation.to(
    navRibbons,
    {
        xPercent: 0,
        yPercent: 0,
        duration: 0.25,
        stagger: 0.18,
    },
    '<0.2'
)
// Nav text elements animation on click
navAnimation.to(
    navOptions,
    {
        autoAlpha: 1,
        duration: 0.3,
        stagger: 0.05,
    },
    '<0.3'
)

// Ribbon transition when clicking internal link
let transitionLinks = document.querySelectorAll('.ribbon-transition')
for (let link of transitionLinks) {
    link.addEventListener('click', showNavRibbons)
}
function showNavRibbons(e = null) {
    let currentTarget = null
    if (e) {
        e.preventDefault()
        currentTarget = e.currentTarget
    }

    let langTransition = false
    if (e && currentTarget.classList.contains('lang-button')) {
        document.querySelector('.lang-button.selected').classList.remove('selected')
        currentTarget.classList.add('selected')
        langTransition = true
    }

    gsap.to(navRibbons, {
        xPercent: 0,
        yPercent: 0,
        duration: 0.25,
        stagger: 0.18,
        onComplete: () => {
            if (!e) return

            if (langTransition) {
                location.href = location.origin + '/' + currentTarget.textContent.toLowerCase() + location.pathname
                return
            }

            location.href = currentTarget.href
        },
    })
}

// Precalculate values
navAnimation.progress(1, true).progress(0, true)

function toggleNav() {
    if (!navOpen) {
        // Reset start values and play animation
        burgerAnimation.invalidate()
        navAnimation.restart()
        return
    }
    navAnimation.reverse()
}

// Donate button hover effect and click listener
donateButton.addEventListener('mouseenter', growDonate)
donateButton.addEventListener('focus', growDonate)
donateButton.addEventListener('mouseleave', shrinkDonate)
donateButton.addEventListener('focusout', shrinkDonate)
donateButton.addEventListener('click', donateTransition)

// Hover effect for donate button
function growDonate() {
    gsap.to([donateCircle, coinContainer, boxContainer], {
        scale: (index) => {
            return index === 0 ? 1.4 : 1.2
        },
        duration: 1,
        ease: 'power2.out',
    })
}
function shrinkDonate() {
    gsap.to([donateCircle, coinContainer, boxContainer], {
        scale: 0.99,
        duration: 1,
        ease: 'power2.out',
    })
}

// When user clicks donate button
function donateTransition(e) {
    e.preventDefault()
    donateButton.removeEventListener('mouseleave', shrinkDonate)
    donateButton.removeEventListener('focusout', shrinkDonate)

    showNavRibbons()

    // Timeline for transition
    const donate = new gsap.timeline()
    donate.to([donateCircle, coinContainer, boxContainer], {
        scale: (index) => {
            return index === 0 ? 6 : 1.4
        },
        duration: 0.5,
        ease: 'power3.inOut',
        overwrite: true,
    })
    donate.to(
        coinRotate,
        {
            yPercent: 150,
            rotation: -180,
            duration: 1,
            ease: 'back.in(3.5)',
        },
        '>-0.3'
    )
    donate.to(
        donateButton,
        {
            autoAlpha: 0,
            duration: 0.2,
            onComplete: () => {
                location.href = donateButton.href
            },
        },
        '>-0.1'
    )
}

// Hover effect for nav links
navLinks.forEach((option, index) => {
    option.index = index
    // Check if user on same page as nav link
    if (
        location.pathname === option.pathname ||
        location.pathname === `${option.pathname}/` ||
        location.pathname === `${option.pathname}/index.html`
    ) {
        option.isCurrent = true
        gsap.set(navLinkDashes[option.index], {
            scaleX: 1,
        })
    } else {
        option.isCurrent = false
    }
    option.addEventListener('mouseenter', animateLink)
    option.addEventListener('mouseleave', reverseLink)
    option.addEventListener('click', linkTransition)
})

// Nav link hover effect
function animateLink() {
    // this = currently hovered nav link
    gsap.to(navOptions[this.index], {
        scale: 1.04,
        duration: 0.3,
    })
    // Only animate the dash on links that the user is not currently on
    if (!this.isCurrent) {
        gsap.to(navLinkDashes[this.index], {
            scaleX: 1,
            duration: 0.3,
            ease: 'circ.out',
        })
    }
}
function reverseLink() {
    // this = currently hovered nav link
    gsap.to(navOptions[this.index], {
        scale: 1,
        duration: 0.3,
    })
    // Only animate the dash on links that the user is not currently on
    if (!this.isCurrent) {
        gsap.to(navLinkDashes[this.index], {
            scaleX: 0,
            duration: 0.3,
            ease: 'circ.in',
        })
    }
}

function linkTransition(e) {
    e.preventDefault()

    if (this.index === 0) {
        // Fade to black if user clicked on home
        gsap.to('.transition-cover', {
            opacity: 1,
            duration: 0.4,
            ease: 'power1.in',
        })
    }
    // Fade out and scale up clicked option
    gsap.to(navOptions[this.index], {
        scale: 3,
        opacity: 0,
        duration: 0.2,
    })
    // Fade out and translate down other options
    gsap.to(navOptions, {
        y: 20,
        opacity: 0,
        delay: 0.1,
        stagger: 0.05,
        duration: 0.2,
    })
    // Return menu icon to unopened position
    gsap.to(navBurger, {
        xPercent: -50,
        yPercent: -50,
        rotation: 0,
        opacity: 1,
        duration: 0.5,
        onComplete: () => {
            location.href = this.href
        },
    })
}

/* -------- END NAV -------- */

// Success and error banners
function showBanner(message, messageFR, type = 1, duration = 5, onClick = null) {
    let banner = document.createElement('div')
    banner.classList.add('banner')
    let color = '#02a95c'
    if (type === 0) color = '#ed1b2f'
    else if (type === 2) color = '#996eff'
    banner.style.backgroundColor = color
    banner.innerHTML = document.documentElement.lang == 'fr' ? messageFR : message
    document.body.appendChild(banner)

    if (onClick) {
        banner.addEventListener('click', onClick)
        banner.style.pointerEvents = 'all'
        banner.style.cursor = 'pointer'
    }

    gsap.set(banner, {
        xPercent: -50,
        y: '-105%',
    })

    gsap.to(banner, {
        y: '2vh',
        duration: 0.4,
        ease: 'power3.out',
    })
    gsap.to(banner, {
        y: '-105%',
        duration: 0.2,
        delay: duration,
        ease: 'power3.out',
        onComplete: () => {
            banner.removeEventListener('click', onClick)
            banner.remove()
        },
    })
}

function redirectToNewWebsite() {
    location.href = document.documentElement.lang == 'fr' ? 'https://fondationcoda.ca/' : 'https://codafoundation.ca/'
}
showBanner(
    'We have a new website, click here to visit it!',
    'Nous avons un nouveau site Web, cliquez ici pour le visiter!',
    1,
    10,
    redirectToNewWebsite
)

// Signature
console.log(
    '%c \u2727 David\u00A0Lu \u2727 ',
    `
    text-align: center;
    font-family: Brush Script MT, Brush Script, Brush Script MT Italic, sans-serif;
    font-weight: bold;
    font-size: 2rem;
    color: #ffffff;
    background: #996eff;
    text-shadow: 2px 2px 2px #00000080;
    `
)
